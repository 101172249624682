<template>
    <div>
        <el-scrollbar class="set-container">
            <div class="main-content">
                <div class="content">
                    <div class="form-content">
                        <el-form :model="addForm" label-width="150px" :rules="rules" ref="addForm"
                                 class="demo-ruleForm">
                            <el-form-item label="商品" class="other-item">
                                <el-button type="primary" @click="chooseGoods">选择商品</el-button>
                                <div class="show-goods">
                                    <div class="goods-item" v-for="(item,index) in chosenGoods" :key="index">
                                        <div class="item-cover">
                                            <img :src="item.img_url" alt="">
                                            <div class="item-shadow" v-if="item.show">
                                                <i class="el-icon-check"></i>
                                            </div>
                                        </div>
                                        <div class="item-text">{{item.goods_name}}</div>
                                        <i class="el-icon-close" @click="delGoods(item,index)"></i>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="换购积分" prop="exchange_points">
                                <el-input-number class="number-input" v-model="addForm.exchange_points" :precision="0"
                                                 :controls="false"
                                                 :min="1" :max="999999999"></el-input-number>
                            </el-form-item>
                            <el-form-item label="限购数量" prop="exchange_limit_num">
                                <el-input-number class="number-input" v-model="addForm.exchange_limit_num"
                                                 :precision="0"
                                                 :controls="false"
                                                 :min="1" :max="999999999"></el-input-number>
                                <span class="limit-text">默认限购5件</span>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="goBack">返回</el-button>
                                <el-button type="primary" style="margin-left: 30px" @click="submitForm('addForm')">保存
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <el-dialog title="选择商品" :visible.sync="dialogVisible" width="40%"
                   custom-class="custom-dialog"
                   :close-on-click-modal="false" @opened="openedDialog" @close="closeDialog">
            <div class="goods-content">
                <div class="top-content">
                    <span class="top-title">商品分类：</span>
                    <el-select v-model="categoryId" placeholder="请选择" @change="changeCategory" clearable>
                        <el-option
                                v-for="item in categoryList"
                                :key="item.category_id"
                                :label="item.category_name"
                                :value="item.category_id">
                        </el-option>
                    </el-select>
                </div>
                <div class="goods-main">
                    <div :class="index > 4?'goods-item bottom-goods':'goods-item'" v-for="(item,index) in goodsList"
                         @click="changeGoods(item)" :key="index">
                        <div class="item-cover">
                            <img :src="item.img_url" alt="">
                            <div class="item-shadow" v-if="item.show">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                        <div class="item-text">{{item.goods_name}}</div>
                        <div class="item-text">{{item.price}}</div>
                    </div>
                </div>
                <el-pagination class="pages-center"
                               :current-page="goodsPages.currentPageNum"
                               :page-size="goodsPages.eachPageNum"
                               layout="prev, pager, next, jumper"
                               :total="goodsPages.total"
                               @current-change="pageCurrentChange">
                </el-pagination>
            </div>
            <div slot="footer" style="text-align: center">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="confirmGoods" style="margin-left: 30px">确定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        exchange_goods_addExangeGoods,
        goods_goodsList,
        categoryList,
        getExchangeGoodsAdmin
    } from '@/config/apis'

    export default {
        data() {
            return {
                infoList: [],
                categoryList: [],
                goodsList: [],
                categoryId: '',
                chosenGoods: [],
                goodsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                addForm: {
                    exchange_points: undefined,
                    exchange_limit_num: 5,
                    exchange_goods_id: null,
                },
                dialogVisible: false,
                rules: {
                    exchange_points: {required: true, message: '请输入换购积分', trigger: 'blur'},
                    exchange_limit_num: {required: true, message: '请输入限购数量', trigger: 'blur'},
                },
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.addForm.exchange_goods_id = this.$route.query.id
                this.getEditInfo()
            }
            this.getCategoryList()
        },
        methods: {
            getEditInfo() {
                getExchangeGoodsAdmin({exchange_goods_id: this.addForm.exchange_goods_id}).then(res => {
                    this.addForm.exchange_points = res.data.exchange_points
                    this.addForm.exchange_limit_num = res.data.exchange_limit_num
                    this.addForm.exchange_points = res.data.exchange_points
                    this.chosenGoods = res.data.goods_list
                }).catch(err => {
                    console.error(err)
                })
            },
            getCategoryList() {
                let params = {
                    paging: '0',
                }
                categoryList(params).then((res) => {
                    this.categoryList = res.data.list
                })
            },
            getGoodsList(id) {
                let params = {
                    paging: '1',
                    pageSize: this.goodsPages.eachPageNum,
                    page: this.goodsPages.currentPageNum,
                    is_exchange: 0
                }
                if (id) {
                    params.category_id = id
                }
                goods_goodsList(params).then((res) => {
                    this.goodsList = res.data.list.map(item => {
                        item.show = false
                        this.chosenGoods.forEach(subItem => {
                            if (subItem.goods_id == item.goods_id) {
                                item.show = true
                            }
                        })
                        return item
                    })
                    this.goodsPages.total = res.data.total
                    if (this.goodsPages.total !== 0 && this.goodsPages.length === 0) {
                        this.goodsPages.currentPageNum--;
                        this.getGoodsList();
                    }
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.goodsPages.currentPageNum = val
                this.getGoodsList()
            },
            goBack() {
                this.$router.go(-1)
            },
            chooseGoods() {
                this.getGoodsList()
                this.dialogVisible = true
            },
            openedDialog() {

            },
            closeDialog() {
                this.resetForm()
            },
            resetForm() {
                this.dialogVisible = false;
            },
            changeCategory(val) {
                this.getGoodsList(val)
            },
            cancel() {
                this.categoryId = ''
                this.dialogVisible = false
            },
            confirmGoods() {
                this.dialogVisible = false
            },
            changeGoods(item) {
                item.show = !item.show
                //默认是false，即不显示shadow
                if (item.show) {
                    if (this.chosenGoods.length > 0) {
                        this.goodsList.forEach(item => {
                            if (this.chosenGoods[0].goods_id && item.goods_id == this.chosenGoods[0].goods_id) {
                                item.show = false
                            }
                        })
                        this.chosenGoods = []
                    }
                    let obj = {
                        img_url: item.img_url,
                        goods_name: item.goods_name,
                        goods_id: item.goods_id,
                        price: item.price
                    }
                    this.chosenGoods.push(obj)
                } else {
                    this.chosenGoods = this.chosenGoods.filter(subItem => {
                        return subItem.goods_id != item.goods_id
                    })
                }
            },
            delGoods(item, index) {
                this.chosenGoods.splice(index, 1)
            },
            submitForm(formName) {
                if (this.chosenGoods.length == 0) {
                    this.$message.warning('请选择商品！')
                    return
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let params = {
                            exchange_points: this.addForm.exchange_points,
                            exchange_limit_num: this.addForm.exchange_limit_num,
                            goods_id: this.chosenGoods[0].goods_id
                        }
                        if (this.addForm.exchange_goods_id) {
                            params.exchange_goods_id = this.addForm.exchange_goods_id
                        }
                        exchange_goods_addExangeGoods(params).then((res) => {
                            this.$message.success(res.msg)
                            this.dialogVisible = false
                            this.goBack()
                        }).catch((err) => {
                            console.error(err)
                        })
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;

        & > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }

    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .form-content {
        flex: 1;
        height: 1%;
        margin-top: 20px;
    }

    .el-input {
        width: 500px;
    }

    .el-textarea {
        width: 500px;
    }

    .goods-main {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .bottom-goods {
            margin-top: 20px;
        }
    }

    .show-goods {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .goods-item {
            width: 120px;
            position: relative;

            &:hover {
                i {
                    opacity: 1;
                }
            }

            i {
                position: absolute;
                right: -12px;
                top: -12px;
                font-size: 24px;
                cursor: pointer;
                opacity: 0;
                color: red;
            }
        }
    }

    .goods-item {
        border: 1px solid #eee;
        width: calc(20% - 20px);
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .item-cover {
            width: 100px;
            height: 100px;
            position: relative;

            img {
                max-width: 100%;
                max-height: 100%;
            }

            .item-shadow {
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 24px;
                }
            }
        }

        &:nth-of-type(5n) {
            margin-right: 0;
        }

        .item-text {
            margin: 10px 0;
        }

        &:hover {
            cursor: pointer;
        }
    }

    ::v-deep .custom-dialog {
        min-width: 500px;
    }

    .other-item {
        position: relative;

        &::after {
            position: absolute;
            content: '*';
            color: #F56C6C;
            margin-right: 4px;
            left: 100px;
            top: 12px;
        }
    }

    .limit-text {
        color: #606266;
        font-size: 14px;
        margin-left: 20px;
    }

    .number-input {
        ::v-deep .el-input__inner {
            text-align: left;
        }
    }

</style>